.App {
  text-align: center;
}

.hidden {
  display: none;
}

.navigation {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  background-color: dimgrey;
  margin: 0 0 2vh;
}

.navigation-item {
  float: left;
  font-family: Roboto, serif;
}

.navigation-item a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;

  &:hover {
    color: white;
    background-color: darkgray;
  }
}

.active {
  background-color: #4CAF50;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-content {
  max-width: 50%;
  margin: auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
